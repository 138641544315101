import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Icons } from '../../components/Icon'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import { FlagItem, SamplePdfType, UserRole } from '../../graphql/generated/graphql'

import { SampleScanTemplateProps } from '.'

export const useSampleScanProps = (): SampleScanTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('scan')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openUpload, setOpenUpload] = useState(false)
  const [materialModal, setMaterialModal] = useState(false)
  const user = useSelector(selectors.auth.user)
  const sampleById = useSelector(selectors.samples.sample)
  const samplePdf = useSelector(selectors.samples.samplePdf)
  const samplePdfRequest = useSelector(selectors.samples.getSamplePdf)
  const [extractedId, setExtractedId] = useState<string | null>(null)
  const [isValidate, setIsValidate] = useState<boolean>(false)
  const [materialFlagItems, setMaterialFlagItems] = useState<FlagItem[] | undefined>([])

  const extractIdFromUrl = (url: string): string | null => {
    const match = url.match(/\/(\d+)$/)
    return match ? match[1] : null
  }

  useEffect(() => {
    dispatch(actions.samples.resetSample())
    setExtractedId(null)
    dispatch(actions.samples.resetSamplePdf())
  }, [dispatch])

  useEffect(() => {
    if (sampleById?.flagItems && extractedId) {
      const filteredItems = sampleById.flagItems.filter(
        (item: any) => item.group?.miraklRef === 'material'
      )
      if (filteredItems.length !== 0) {
        setMaterialModal(true)
        setMaterialFlagItems(filteredItems)
      } else {
        const link = router(routesPath.sampleSheetData, {
          sampleId: extractedId as string,
        })
        navigate(link)
      }
    }
  }, [extractedId, navigate, sampleById])

  useEffect(() => {
    if (extractedId) {
      setMaterialFlagItems([])
      dispatch(actions.samples.getSampleByIdRequest({ sampleId: extractedId }))
    }
  }, [dispatch, extractedId])

  useEffect(() => {
    if (materialFlagItems && materialFlagItems.length > 0 && extractedId) {
      setOpenUpload(true)
    }
  }, [dispatch, extractedId, materialFlagItems])

  useEffect(() => {
    if (samplePdf && extractedId === sampleById?.id && materialFlagItems && isValidate) {
      window.open(samplePdf, '_blank')
      setIsValidate(false)
      const link = router(routesPath.sampleSheetData, {
        sampleId: extractedId as string,
      })
      navigate(link)
    }
  }, [extractedId, isValidate, materialFlagItems, navigate, sampleById?.id, samplePdf])

  return useMemo(
    () => ({
      layoutProps: {
        marginMainContent: false,
        ...layoutProps,
        bottomActions:
          user?.role !== UserRole.Rex &&
          user?.role !== UserRole.Marketplace &&
          user?.role !== UserRole.InputOperator
            ? {
                next: {
                  text: 'Scan multiple',
                  href: '/edition-multiple',
                  rightIconProps: {
                    icon: Icons.plus,
                    width: 18,
                    height: 18,
                  },
                },
              }
            : undefined,
      },
      closeBtn: () => {
        const link = router(routesPath.home)
        navigate(link)
      },
      scannerProps: {
        text: 'Scannez le QR code de l’échantillon',
        onDetect: (result: string) => {
          const id = extractIdFromUrl(result)
          if (id) {
            console.log('id', id)
            setExtractedId(id)
          } else {
            console.error('Impossible d’extraire un ID valide de l’URL.')
          }
        },
        openError: () => setOpenUpload(true),
      },
      uploadModal: {
        title: materialModal
          ? "Souhaitez vous imprimer l'étiquette composition de l'échantillon ?"
          : 'Caméra indisponible',
        text: !materialModal
          ? 'Le scan est indisponible sans caméra. Veuillez vérifier dans les paramètres de votre appareil pour l’activer.'
          : undefined,
        button: {
          text: materialModal ? 'Annuler' : 'Retour',
          onClick: () => {
            const link = materialModal
              ? router(routesPath.sampleSheetData, {
                  sampleId: extractedId as string,
                })
              : router(routesPath.home)
            navigate(link)
          },
        },
        submitButton: {
          text: 'Oui',
          rightIconProps: { icon: Icons.check, height: 20, width: 20 },
          onClick: () => {
            extractedId &&
              dispatch(
                actions.samples.getSamplePdfRequest({
                  sampleId: extractedId,
                  type: SamplePdfType.Composition,
                })
              )
            setIsValidate(true)
          },
          isPending: samplePdfRequest.pending,
        },
        uploadHandler: undefined,
        isOpen: openUpload,
        onClose: () => {
          const link = router(routesPath.home)
          navigate(link)
        },
      },
      extractedId,
    }),
    [
      layoutProps,
      user?.role,
      materialModal,
      samplePdfRequest.pending,
      openUpload,
      extractedId,
      navigate,
      dispatch,
    ]
  )
}
