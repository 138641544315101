import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  FlagGroup,
  InputMaybe,
  PaginatorInfo,
  Sample,
  SampleCountByUniverse,
  SampleCreateInput,
  SampleDeleteMutation,
  SampleFormGroup,
  SampleHistoryItem,
  SamplePdfType,
  SampleStatus,
  SampleUpdateInput,
  SampleUpdateManyInput,
  SamplesCountByQuery,
  SamplesCountByQueryVariables,
  SamplesExportInput,
} from '../../graphql/generated/graphql'
import { InitialSampleData, SampleData } from '../../types/sampleData'
import { InitialSampleDataAllInfo, SampleDataAllInfo } from '../../types/sampleDataAllInfo'

import { actionTypes } from './types'

//
// Services
//

export const sampleCreateService = getCustomService<
  'sampleCreate',
  null,
  {
    samples: {
      input: SampleCreateInput
    }[]
  }
>('sampleCreate')

export const sampleRestoreService = getCustomService<
  'sampleRestore',
  null,
  {
    sampleId: string
  }
>('sampleRestore')
export const exportSamplesService = getCustomService<
  'exportSamples',
  null,
  {
    filters: SamplesExportInput
  }
>('exportSamples')
export const sampleUpdateService = getCustomService<
  'sampleUpdate',
  null,
  {
    sampleId: string
    input: SampleUpdateInput
  }
>('sampleUpdate')

export const sampleDeleteService = getCustomService<
  'sampleDelete',
  SampleDeleteMutation,
  {
    sampleId: string
  }
>('sampleDelete')

export const sampleCountByUniverseService = getCustomService<
  'sampleCountByUniverse',
  null,
  {
    exhibitionApiId: string
  }
>('sampleCountByUniverse')

export const samplesCountByService = getCustomService<
  'samplesCountBy',
  null,
  SamplesCountByQueryVariables
>('samplesCountBy')

export const sampleFormByUniverseService = getCustomService<
  'getSampleFormByUniverse',
  null,
  {
    collectionId: string
    universeEbmsId?: InputMaybe<string> | undefined
    sampleId?: InputMaybe<string> | undefined
  }
>('getSampleFormByUniverse')

export const getSampleByIdService = getCustomService<
  'getSampleById',
  null,
  {
    sampleId: string
  }
>('getSampleById')

export const sampleSearchFiltersService = getCustomService<
  'sampleSearchFilters',
  null,
  {
    universeEbmsId: string
  }
>('sampleSearchFilters')

export const getSamplePdfService = getCustomService<
  'getSamplePdf',
  null,
  {
    sampleId: string
    type?: SamplePdfType
  }
>('getSamplePdf')

export const getFlagGroupsByUniverseService = getCustomService<
  'getFlagGroupsByUniverse',
  null,
  {
    universeEbmsId: string
  }
>('getFlagGroupsByUniverse')

export const getSampleHistoryByIdService = getCustomService<
  'getSampleHistoryById',
  null,
  {
    sampleId: string
  }
>('getSampleHistoryById')

export const sampleAttachShootingsService = getCustomService<
  'sampleAttachShootings',
  null,
  {
    sampleId: string
    uploadPaths: string | string[]
  }
>('sampleAttachShootings')

export const sampleUpdateImageLabelService = getCustomService<
  'sampleUpdateImageLabel',
  null,
  {
    sampleLabelId: string
    uploadPath: string
  }
>('sampleUpdateImageLabel')

export const sampleCreateImageLabelService = getCustomService<
  'sampleCreateImageLabel',
  null,
  {
    sampleId: string
    uploadPath: string
  }
>('sampleCreateImageLabel')

export const sampleUpdateStatusService = getCustomService<
  'sampleUpdateStatus',
  null,
  {
    sampleId: string
    status: SampleStatus
  }
>('sampleUpdateStatus')

export const flagItemUpdateService = getCustomService<
  'flagItemUpdate',
  null,
  {
    flagItemId: string
    name: string
    miraklCategoryLabel: string
    order?: number
  }
>('flagItemUpdate')

export const flagItemDeleteService = getCustomService<
  'flagItemDelete',
  null,
  {
    flagItemId: string
  }
>('flagItemDelete')

export const flagItemCreateService = getCustomService<
  'flagItemCreate',
  null,
  {
    flagGroupId: string
    name: string
    miraklCategoryLabel: string
    order?: number
  }
>('flagItemCreate')

export const sampleUpdateManyService = getCustomService<
  'sampleUpdateMany',
  null,
  {
    sampleIds: string[]
    input: SampleUpdateManyInput
  }
>('sampleUpdateMany')

export const sampleUpdateWithUniverseService = getCustomService<
  'sampleUpdateWithUniverse',
  null,
  {
    sampleId: string
    universeEbmsId: string
    subUniverseEbmsId: string
  }
>('sampleUpdateWithUniverse')

export const getSamplesService = getCustomService<
  'getSamples',
  null,
  {
    q: string
    forumsIds?: string[] | null
    universesEbmsIds?: string[] | null
    pavilionsEbmsIds?: string[] | null
    countries?: string[] | null
    exhibitionsApiIds?: string[] | null
    flagItems?: string[] | null
    first: number
    page: number
  }
>('getSamples')

export const getSamplesNumberService = getCustomService<
  'getSamplesNumber',
  null,
  {
    q: string
    forumsIds?: string[] | null
    universesEbmsIds?: string[] | null
    pavilionsEbmsIds?: string[] | null
    countries?: string[] | null
    exhibitionsApiIds?: string[] | null
    flagItems?: string[] | null
    withTrashed: boolean | null
    first: number
    page: number
  }
>('getSamplesNumber')

//
// Initial state
//

export type SamplesState = {
  sample: Sample | undefined
  samples: Sample[] | undefined
  samplesNumber: PaginatorInfo | undefined
  samplesPaginator: PaginatorInfo | undefined
  samplesCount: SampleCountByUniverse[] | undefined
  samplesCountBy: SamplesCountByQuery | undefined
  sampleHistory: SampleHistoryItem[] | undefined
  samplePdf: string | undefined
  sampleFilters: any
  sampleData: SampleData
  sampleFormByUniverse: SampleFormGroup[]
  sampleDataAllInfo: SampleDataAllInfo
  flagGroupsByUniverse: FlagGroup[] | undefined
  // services
  sampleCreate: typeof sampleCreateService.state
  sampleUpdate: typeof sampleUpdateService.state
  sampleDelete: typeof sampleDeleteService.state
  sampleRestore: typeof sampleRestoreService.state
  getSamplesCountBy: typeof samplesCountByService.state
  getSampleFormByUniverse: typeof sampleFormByUniverseService.state
  getSamples: typeof getSamplesService.state
  getSamplesNumber: typeof getSamplesNumberService.state
  getFlagGroupsByUniverse: typeof getFlagGroupsByUniverseService.state
  getSampleById: typeof getSampleByIdService.state
  sampleAttachShootings: typeof sampleAttachShootingsService.state
  sampleUpdateImageLabel: typeof sampleUpdateImageLabelService.state
  sampleCreateImageLabel: typeof sampleCreateImageLabelService.state
  getSampleHistoryById: typeof getSampleHistoryByIdService.state
  getSamplePdf: typeof getSamplePdfService.state
  sampleUpdateStatus: typeof sampleUpdateStatusService.state
  sampleUpdateMany: typeof sampleUpdateManyService.state
  sampleUpdateWithUniverse: typeof sampleUpdateWithUniverseService.state
  flagItemCreate: typeof flagItemCreateService.state
  flagItemUpdate: typeof flagItemUpdateService.state
  flagItemDelete: typeof flagItemDeleteService.state
  sampleSearchFilters: typeof sampleSearchFiltersService.state
  sampleCountByUniverse: typeof sampleCountByUniverseService.state
  exportSamples: typeof exportSamplesService.state
}

const initialState: SamplesState = {
  sample: undefined,
  samples: undefined,
  samplesNumber: undefined,
  samplesPaginator: undefined,
  samplesCount: undefined,
  samplesCountBy: undefined,
  sampleHistory: undefined,
  samplePdf: undefined,
  sampleFilters: undefined,
  sampleData: InitialSampleData,
  sampleDataAllInfo: InitialSampleDataAllInfo,
  sampleFormByUniverse: [] as SampleFormGroup[],
  flagGroupsByUniverse: undefined,
  // services
  sampleCreate: sampleCreateService.state,
  sampleUpdate: sampleUpdateService.state,
  sampleDelete: sampleDeleteService.state,
  sampleRestore: sampleRestoreService.state,
  getSamplesCountBy: samplesCountByService.state,
  getSampleFormByUniverse: sampleFormByUniverseService.state,
  getSamples: getSamplesService.state,
  getSamplesNumber: getSamplesNumberService.state,
  getFlagGroupsByUniverse: getFlagGroupsByUniverseService.state,
  getSampleById: getSampleByIdService.state,
  sampleAttachShootings: sampleAttachShootingsService.state,
  sampleUpdateImageLabel: sampleUpdateImageLabelService.state,
  sampleCreateImageLabel: sampleCreateImageLabelService.state,
  getSampleHistoryById: getSampleHistoryByIdService.state,
  getSamplePdf: getSamplePdfService.state,
  sampleUpdateStatus: sampleUpdateStatusService.state,
  sampleUpdateMany: sampleUpdateManyService.state,
  sampleUpdateWithUniverse: sampleUpdateWithUniverseService.state,
  flagItemCreate: flagItemCreateService.state,
  flagItemUpdate: flagItemUpdateService.state,
  flagItemDelete: flagItemDeleteService.state,
  sampleSearchFilters: sampleSearchFiltersService.state,
  sampleCountByUniverse: sampleCountByUniverseService.state,
  exportSamples: exportSamplesService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'samples',
  initialState,
  reducers: {
    resetSamples: () => initialState,
    setSampleData: (state, { payload }: actionTypes.setSampleData) => {
      state.sampleData = payload
    },
    setSample: (state, { payload }: actionTypes.setSample) => {
      state.sample = payload
    },
    setSamples: (state, { payload }: actionTypes.setSamples) => {
      state.samples = payload
    },
    setSamplesPaginator: (state, { payload }: actionTypes.setSamplesPaginator) => {
      state.samplesPaginator = payload
    },
    setSamplesNumber: (state, { payload }: actionTypes.setSamplesNumber) => {
      state.samplesNumber = payload
    },
    setSamplesCount: (state, { payload }: actionTypes.setSamplesCount) => {
      state.samplesCount = payload
    },
    setSamplesCountBy: (state, { payload }: actionTypes.setSamplesCountBy) => {
      state.samplesCountBy = payload
    },
    setSampleFilters: (state, { payload }: actionTypes.setSampleFilters) => {
      state.sampleFilters = payload
    },
    setSampleHistory: (state, { payload }: actionTypes.setSampleHistory) => {
      state.sampleHistory = payload
    },
    setSamplePdf: (state, { payload }: actionTypes.setSamplePdf) => {
      state.samplePdf = payload
    },
    setSampleDataAllInfo: (state, { payload }: actionTypes.setSampleDataAllInfo) => {
      state.sampleDataAllInfo = payload
    },
    setSampleFormByUniverse: (state, { payload }: actionTypes.setSampleFormByUniverse) => {
      state.sampleFormByUniverse = payload
    },
    setFlagGroupsByUniverse: (state, { payload }: actionTypes.setFlagGroupsByUniverse) => {
      state.flagGroupsByUniverse = payload
    },
    resetSampleData: (state) => {
      state.sampleData = initialState.sampleData
    },
    resetExportSamples: (state) => {
      state.exportSamples = initialState.exportSamples
    },
    resetSampleSearch: (state) => {
      state.getSamples = initialState.getSamples
    },
    resetSamplePdf: (state) => {
      state.samplePdf = initialState.samplePdf
    },
    resetFlagItemCreate: (state) => {
      state.flagItemCreate = initialState.flagItemCreate
    },
    resetFlagItemUpdate: (state) => {
      state.flagItemUpdate = initialState.flagItemUpdate
    },
    resetFlagItemDelete: (state) => {
      state.flagItemDelete = initialState.flagItemDelete
    },
    resetSampleDataAllInfo: (state) => {
      state.sampleDataAllInfo = initialState.sampleDataAllInfo
    },
    resetSampleCreate: (state) => {
      state.sampleCreate = initialState.sampleCreate
    },
    resetSampleById: (state) => {
      state.sampleCreate.success = false
    },
    resetSample: (state) => {
      state.sample = initialState.sample
    },
    resetSampleUpdate: (state) => {
      state.sampleUpdate = initialState.sampleUpdate
    },
    resetSampleRestore: (state) => {
      state.sampleRestore = initialState.sampleRestore
    },
    resetSampleDelete: (state) => {
      state.sampleDelete = initialState.sampleDelete
    },
    resetSampleUpdateMany: (state) => {
      state.sampleUpdateMany = initialState.sampleUpdateMany
    },
    resetSampleUpdateWithUniverseService: (state) => {
      state.sampleUpdateWithUniverse = initialState.sampleUpdateWithUniverse
    },
    resetSampleUpdateStatus: (state) => {
      state.sampleUpdateStatus = initialState.sampleUpdateStatus
    },
    resetSampleFilters: (state) => {
      state.sampleFilters = initialState.sampleFilters
    },
    ...sampleCreateService.reducers,
    ...sampleUpdateService.reducers,
    ...sampleDeleteService.reducers,
    ...sampleRestoreService.reducers,
    ...samplesCountByService.reducers,
    ...sampleFormByUniverseService.reducers,
    ...getSamplesService.reducers,
    ...getSamplesNumberService.reducers,
    ...getFlagGroupsByUniverseService.reducers,
    ...getSampleByIdService.reducers,
    ...sampleAttachShootingsService.reducers,
    ...sampleUpdateImageLabelService.reducers,
    ...sampleCreateImageLabelService.reducers,
    ...getSampleHistoryByIdService.reducers,
    ...getSamplePdfService.reducers,
    ...sampleUpdateStatusService.reducers,
    ...sampleUpdateManyService.reducers,
    ...sampleUpdateWithUniverseService.reducers,
    ...flagItemCreateService.reducers,
    ...flagItemDeleteService.reducers,
    ...flagItemUpdateService.reducers,
    ...sampleSearchFiltersService.reducers,
    ...sampleCountByUniverseService.reducers,
    ...exportSamplesService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const sample = (state: RootState) => root(state).sample
const samples = (state: RootState) => root(state).samples
const samplesNumber = (state: RootState) => root(state).samplesNumber
const exportSamples = (state: RootState) => root(state).exportSamples
const samplesPaginator = (state: RootState) => root(state).samplesPaginator
const samplesCount = (state: RootState) => root(state).samplesCount
const samplesCountBy = (state: RootState) => root(state).samplesCountBy
const getSamplesCountBy = (state: RootState) => root(state).getSamplesCountBy
const sampleFilters = (state: RootState) => root(state).sampleFilters
const samplePdf = (state: RootState) => root(state).samplePdf
const sampleHistory = (state: RootState) => root(state).sampleHistory
const flagGroupsByUniverse = (state: RootState) => root(state).flagGroupsByUniverse
const sampleData = (state: RootState) => root(state).sampleData
const sampleDataAllInfo = (state: RootState) => root(state).sampleDataAllInfo
const sampleFormByUniverse = (state: RootState) => root(state).sampleFormByUniverse
const sampleCreate = (state: RootState) => root(state).sampleCreate
const sampleUpdate = (state: RootState) => root(state).sampleUpdate
const sampleDelete = (state: RootState) => root(state).sampleDelete
const sampleRestore = (state: RootState) => root(state).sampleRestore
const getSamples = (state: RootState) => root(state).getSamples
const getSamplesNumber = (state: RootState) => root(state).getSamplesNumber
const getSamplePdf = (state: RootState) => root(state).getSamplePdf
const getSampleById = (state: RootState) => root(state).getSampleById
const getSampleFormByUniverse = (state: RootState) => root(state).getSampleFormByUniverse
const getFlagGroupsByUniverse = (state: RootState) => root(state).getFlagGroupsByUniverse
const sampleAttachShootings = (state: RootState) => root(state).sampleAttachShootings
const sampleUpdateImageLabel = (state: RootState) => root(state).sampleUpdateImageLabel
const sampleCreateImageLabel = (state: RootState) => root(state).sampleCreateImageLabel
const getSampleHistoryById = (state: RootState) => root(state).getSampleHistoryById
const sampleUpdateStatus = (state: RootState) => root(state).sampleUpdateStatus
const sampleUpdateMany = (state: RootState) => root(state).sampleUpdateMany
const sampleUpdateWithUniverse = (state: RootState) => root(state).sampleUpdateWithUniverse
const flagItemCreate = (state: RootState) => root(state).flagItemCreate
const flagItemUpdate = (state: RootState) => root(state).flagItemUpdate
const flagItemDelete = (state: RootState) => root(state).flagItemDelete
const sampleSearchFilters = (state: RootState) => root(state).sampleSearchFilters
const samplesCountByUniverse = (state: RootState) => root(state).sampleCountByUniverse

export const selectors = {
  sample,
  samples,
  samplesNumber,
  exportSamples,
  samplesPaginator,
  samplesCount,
  samplesCountBy,
  getSamplesCountBy,
  samplePdf,
  sampleHistory,
  sampleFilters,
  flagGroupsByUniverse,
  sampleData,
  sampleDataAllInfo,
  sampleFormByUniverse,
  sampleCreate,
  sampleUpdate,
  sampleDelete,
  sampleRestore,
  getSamples,
  getSamplesNumber,
  getSamplePdf,
  getSampleById,
  getSampleFormByUniverse,
  getFlagGroupsByUniverse,
  sampleAttachShootings,
  sampleUpdateImageLabel,
  sampleCreateImageLabel,
  getSampleHistoryById,
  sampleUpdateStatus,
  sampleUpdateMany,
  sampleUpdateWithUniverse,
  flagItemCreate,
  flagItemUpdate,
  flagItemDelete,
  sampleSearchFilters,
  samplesCountByUniverse,
}
