import { ForumBoxItem, SampleFormItem, SampleLabel, Scalars } from '../graphql/generated/graphql'

export type CustomSampleFormItem = {
  checked: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  value?: Scalars['String'] | null
}

export interface SampleDataAllInfo {
  routeType: string | undefined
  label: SampleLabel[] | undefined
  exhibitorRef: string | undefined
  mkpForum: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  forum: {
    id: string | undefined
    applyToAllCollection?: boolean
  }
  subForum: {
    id: string | undefined
    applyToAllCollection?: boolean
  }
  category: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  productDestination: {
    id: string | undefined
    items: SampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  pattern: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  performanceCode: {
    id: string | undefined
    items: SampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  shooting: {
    id: string | undefined
    items: SampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  boxe: {
    id: string | undefined
    items: ForumBoxItem | undefined
    applyToAllCollection?: boolean
  }
  numberEnd: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  patternType: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  animal: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  aspect: {
    id: string | undefined
    items: SampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  certifications: {
    id: string | undefined
    items: SampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  unitValue: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  technologyActivity: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  tanningType: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  accessorieType: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  material: {
    id: string | undefined
    items: CustomSampleFormItem[] | undefined
    applyToAllCollection?: boolean
  }
  manufacturingType: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  color: {
    id: string | undefined
    items: SampleFormItem | undefined
    applyToAllCollection?: boolean
  }
  [key: string]:
    | {
        id: string | undefined
        items: SampleFormItem | SampleFormItem[] | undefined
        applyToAllCollection?: boolean
      }
    | {
        id: string | undefined
        items: ForumBoxItem | undefined
      }
    | {
        id: string | undefined
        applyToAllCollection?: boolean
      }
    | string
    | string[]
    | SampleLabel[]
    | SampleFormItem[]
    | SampleFormItem
    | undefined
}

export const InitialSampleDataAllInfo = {
  routeType: undefined,
  forum: {
    id: undefined,
    applyToAllCollection: false,
  },
  subForum: {
    id: undefined,
    applyToAllCollection: false,
  },
  category: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  productDestination: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  pattern: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  performanceCode: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  shooting: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  numberEnd: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  patternType: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  animal: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  aspect: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  certifications: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  unitValue: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  technologyActivity: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  label: undefined,
  tanningType: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  boxe: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  exhibitorRef: undefined,
  mkpForum: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  accessorieType: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  material: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  manufacturingType: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
  color: {
    id: undefined,
    items: undefined,
    applyToAllCollection: false,
  },
}
