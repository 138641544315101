/* eslint-disable no-case-declarations */
import { Sample, SampleLabel } from '../../graphql/generated/graphql'
import { InitialSampleDataAllInfo, SampleDataAllInfo } from '../../types/sampleDataAllInfo'

export const mapSampleDataAllInfo = (data: Sample) => {
  const mappedData: SampleDataAllInfo = { ...InitialSampleDataAllInfo }

  data.flagItems.forEach((item) => {
    if (item.group && item.group.miraklRef) {
      switch (item.group.miraklRef) {
        case 'category':
          mappedData.category = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'performance_code':
          const performanceCodeItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'performance_code')
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
            }))

          mappedData.performanceCode = {
            id: item.group.id,
            items: performanceCodeItems,
            applyToAllCollection: false,
          }
          break
        case 'NON_MARKETPLACE_forum':
          mappedData.mkpForum = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'end_use':
        case 'end_use_leather':
        case 'yarn_end_use':
          const endUseItems = data.flagItems
            .filter(
              (item) =>
                item.group.miraklRef === 'end_use' ||
                item.group.miraklRef === 'end_use_leather' ||
                item.group.miraklRef === 'yarn_end_use'
            )
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
            }))

          mappedData.productDestination = {
            id: item.group.id,
            items: endUseItems,
            applyToAllCollection: false,
          }
          break
        case 'pattern':
          mappedData.pattern = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'animal':
          mappedData.animal = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'certification':
          const certificationsItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'certification')
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
            }))

          mappedData.certifications = {
            id: item.group.id,
            items: certificationsItems,
            applyToAllCollection: false,
          }
          break
        case 'NON_MARKETPLACE_shooting':
          const shootingItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'NON_MARKETPLACE_shooting')
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
            }))

          mappedData.shooting = {
            id: item.group.id,
            items: shootingItems,
            applyToAllCollection: false,
          }
          break
        case 'type_of_accessories':
          mappedData.accessorieType = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'unit_value':
        case 'yarn_unit_value':
          mappedData.unitValue = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'pattern_type':
          mappedData.patternType = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'material':
          const materialItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'material')
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
              ...(item.pivot ? { value: item.pivot.value } : {}),
            }))
          mappedData.material = {
            id: item.group.id,
            items: materialItems,
            applyToAllCollection: false,
          }
          break
        case 'type_of_tanning':
          mappedData.tanningType = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'yarn_number_end':
        case 'number_end':
          mappedData.numberEnd = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'yarn_aspect':
        case 'aspect':
        case 'aspects':
          const aspectsItems = data.flagItems
            .filter(
              (item) =>
                item.group.miraklRef === 'aspect' ||
                item.group.miraklRef === 'yarn_aspect' ||
                item.group.miraklRef === 'aspects'
            )
            .map((item) => ({
              checked: false,
              id: item.id,
              name: item.name,
              value: (item.pivot && (item.pivot.value as string)) || '',
            }))
          mappedData.aspect = {
            id: item.group.id,
            items: aspectsItems,
            applyToAllCollection: false,
          }
          break
        case 'yarn_technology_activity':
        case 'technology_activity':
          mappedData.technologyActivity = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'type_of_manufacturing':
          mappedData.manufacturingType = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        case 'color':
          mappedData.color = {
            id: item.group.id,
            items: {
              checked: false,
              id: item.id,
              name: item.name,
            },
            applyToAllCollection: false,
          }
          break
        default:
          break
      }
      mappedData.routeType = data && data.universe && data.universe.name
      mappedData.forum = {
        id: data.forum?.id,
        applyToAllCollection: false,
      }
      mappedData.subForum = {
        id: data.subForum?.id,
        applyToAllCollection: false,
      }
      mappedData.exhibitorRef = data.name
      mappedData.label = data.labels ? data.labels.map((label: SampleLabel) => label) : undefined
    }
  })

  return mappedData
}
