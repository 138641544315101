import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleGeneratePdf = getMutationService({
  mutation: gql(`
    mutation sampleGeneratePdf($sampleId: ID!, $type: SamplePdfType) {
		sampleGeneratePdf(sampleId: $sampleId, type: $type)
	}
    `),
  transformer: (response) => {
    return response
  },
})
